import React, {useEffect, useReducer, useState} from 'react';
import styled from "styled-components";

const tf = require('@tensorflow/tfjs');

const CLASSIFICATIONS = [
  'Covering Face',
  'Normal',
  'Looking at Left',
  'Looking at Right',
  'Facing Down',
  'Normal',
  'Facing Right',
  'Facing Up',
  'Multiple Persons',
  'Using Device',
];

const Cheatless = (props) => {
  const [prediction, setPrediction] = useState('Normal');
  const imgHeight = 288
  const imgWidth = 512

  useEffect(() => {
    tf.loadLayersModel('/model/model.json').then(cheatlessModel => {

      let currentCheat = 'Normal';
      let cheatTimeline = new Array(6).fill('Normal');

      const refresh = () => {
        if (!props.webcam.current) {
          setPrediction("Webcam not available.");
          return;
        }
        const screenshot = props.webcam.current.getScreenshot({
          width: imgWidth,
          height: imgHeight
        });

        const image = new Image();
        image.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;

          const context = canvas.getContext('2d');
          context.drawImage(image, 0, 0);

          const rawImageData = context.getImageData(0, 0, canvas.width, canvas.height);

          let imageData = Array(512).fill(0)
            .map(() => new Array(288).fill(0)
              .map(() => new Array(3).fill(0)));

          rawImageData.data.forEach((value, i) => {
            const index3 = i % 4;
            const index2 = Math.floor(i / 4) % imgHeight;
            const index1 = Math.floor(i / imgHeight / 4);

            if (index3 < 3) imageData[index1][index2][index3] = value;
          });

          const tensorArray = [tf.tensor([imageData])];
          const predictionId = cheatlessModel.predict(tensorArray).argMax(-1).dataSync()[0];
          setPrediction(CLASSIFICATIONS[predictionId]);

          cheatTimeline = [...cheatTimeline.slice(1), CLASSIFICATIONS[predictionId]]

          const keys = [...new Set(cheatTimeline)];

          const cheatHistory = {};

          keys.forEach(key => {
            cheatHistory[key] = cheatTimeline.filter(value => value === key).length;
          });

          for (const key in cheatHistory) {
            if (cheatHistory.hasOwnProperty(key)) {
              if (cheatHistory[key] > 3) {
                if (currentCheat !== key) {
                  const time = Math.floor((new Date() - props.startTime) / 1000)
                  props.dipatch({
                    type: 'add',
                    payload: {
                      time: `00:${Math.floor(time / 60).toString().padStart(2, '0')}:${(time % 60).toString().padStart(2, '0')}`,
                      activity: key
                    }
                  });
                }
                currentCheat = key;
                break;
              }
            }
          }

          setTimeout(refresh, 500);
        }
        image.src = screenshot;
      }
      refresh();
    }).catch(reason => {
      console.log(reason);
      setPrediction('Error');
    });
  }, []);

  return (
    <Label>
      {prediction}
    </Label>
  )
};

const Label = styled.label`
                    }
          position: absolute;
          left: 8px;
          top: 8px;
          width: 100%;
          color: white;
          font-weight: bold;
          font-size: 20px;
          text-shadow: 2px 0 black;
`;

export default Cheatless;
