import React from 'react';
import styled from 'styled-components';

const Checkbox = (props) => {
    return (
        <Div>
            <div>
                <InputLabel>
                    <Box
                        name="behavior"
                        type="checkbox"
                        defaultChecked={props.defaultChecked || false}
                        value={props.name}
                    />
                    {props.name}
                </InputLabel>
            </div>
            <div>
                <ToleranceInput placeholder="Low (3 seconds)" defaultValue="3" disabled={!props.defaultChecked}>
                  <option value="1">Very Low (1 second)</option>
                  <option value="3">Low (3 seconds)</option>
                  <option value="5">Medium (5 seconds)</option>
                  <option value="10">High (10 seconds)</option>
                  <option value="15">Very High (15 seconds)</option>
                </ToleranceInput>
            </div>
        </Div>
    )
};

const Div = styled.div`
    display: flex;
    justify-content: space-between;
`

const InputLabel = styled.div`
    margin-bottom: 20px;
    font-size: 12px;
`

const Box = styled.input`
    vertical-align: middle;
    margin-right: 8px;
`

const Label = styled.h6`
    margin: 0 auto;
    background-color: #707070;
    border: 1px;
    padding: 5px;
    border-radius: 3px;
`

const ToleranceInput = styled.select`
    background-color: #707070;
    border-radius: 4px;
    color: #FFF;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #999;
    }
    :-ms-input-placeholder {
       color: #999;
    }
`


export default Checkbox;
