import React from 'react';
import Webcam from "react-webcam";

import AudioAnalyser from './Solo/AudioAnalyser';
import Checkboxes from './Checkboxes';

import styled from 'styled-components'
import CheatlessWebcam from "./CheatlessWebcam";
import StudentActivityLog from "./StudentActivityLog";

const StudentView = (props) => {

    return (
        <Div>
            <CheatlessWebcam mirrored="true" />
            <StudentActivityLog dispatch={props.dispatch}/>
            <div style={{flex: 1}}>
                <Checkboxes />
                {props.audio ? <AudioAnalyser audio={props.audio} /> : ''}
            </div>
        </Div>
    )
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
`
const Table = styled.table`
    border-collapse: collapse;
    overflow-y: scroll;
`
const TableHeader = styled.thead`
    border: 1px solid #ddd;
    padding: 8px;
`
const TableHeading = styled.th`
    padding: 15px;
    text-align: left;
    background-color: rgb(235, 149, 50);
    color: white;
`

const TableRow = styled.tr`
    background-color: ${props => props.index % 2 == 0 ? "rgb(46, 49, 49)" : "rgb(151, 151, 151)"};
    color: white;
`

const TableData = styled.td`
    padding: 15px;
`


export default StudentView;
