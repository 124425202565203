import React from 'react';

import styled from 'styled-components'

const StudentActivityLog = (props) => {
    return (
        <Div>
            <Table>
                <TableHeader>
                    <tr>
                        <TableHeading>Time</TableHeading>
                        <TableHeading>Activity</TableHeading>
                    </tr>
                </TableHeader>
                <TableBody>
                    {props.state.logs.length ? props.state.logs.slice(0).reverse().map((log, index) => (
                        <TableRow index={index} key={index}>
                            <TableData>{log.time}</TableData>
                            <TableData>{log.activity}</TableData>
                        </TableRow>
                    )) : (
                        <TableRow index={1} key={1}>
                            <TableData>0:00:00</TableData>
                            <TableData>No Activity yet</TableData>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Div>
    )
};

const Div = styled.div`
    overflow-y: scroll;
    height: 500px;
`

const Table = styled.table`
    border-collapse: collapse;
    width: 100%;

`
const TableHeader = styled.thead`
    border: 1px solid #ddd;
    padding: 8px;
`
const TableHeading = styled.th`
    padding: 15px;
    text-align: left;
    background-color: #A7803F;
    color: white;
`

const TableRow = styled.tr`
    background-color: ${props => props.index % 2 === 0 ? "#4C464B" : "#6C6C6C"};
    color: white;
`
const TableBody = styled.tbody`
`

const TableData = styled.td`
    padding: 15px;
`


export default StudentActivityLog;
