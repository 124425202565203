import React from 'react';
import styled from 'styled-components'
import Checkboxes from './Checkboxes';
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

const Drawer = (props) => {
    return (
        <Div open={props.open}>
            <Toggle onClick={props.toggleDrawer} open={props.open}>
               <Icon>
                 {props.open ? <FaChevronRight /> : <FaChevronLeft />}
               </Icon>
            </Toggle>
            <div style={{margin: '0 24px', width: 375}}>
                <h3 style={{textAlign: 'center'}}>Detectable Flags</h3>
                <h5>Please enable those you want to apply to your students.</h5>
                <Checkboxes />
            </div>
        </Div>
    )
};

const Div = styled.div`
    height: 100%;
    position: fixed;
    top: 80px;
    right: ${props => (props.open ? '0': '-423px')};
    background-color: #3C3C3C;
    color: white;
    display: flex;
    flex-direction: column;
    transition: 0.5s ease-in-out;
    -webkit-box-shadow: -6px 0px 12px 2px rgba(0,0,0,0.39);
    -moz-box-shadow: -6px 0px 12px 2px rgba(0,0,0,0.39);
    box-shadow: -6px 0px 12px 2px rgba(0,0,0,0.39);
`

const Icon = styled.div`
    font-size: 24px;
    padding: 18px;
    cursor: pointer;
`;

const Toggle = styled.div`
    position: absolute;
    left: -48px;
    top: 48px;
    width: 48px;
    border-top-left-radius: 64px;
    border-bottom-left-radius: 64px;
    background-color: ${props => (props.open ? '#525252': '#e7a200')};
    transition: 0.5s ease-in-out;
`;

export default Drawer;
