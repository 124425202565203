import React, {useEffect, useState} from 'react';
import styled from 'styled-components'

const Header = (props) => {

    useEffect(() => {
      const refresh = () => {
          props.setTime(Math.floor((new Date() - props.startTime) / 1000));
          setTimeout(refresh, 1000);
      };
      refresh();
    }, []);
    return (
        <HeaderDiv>
            <img src={"logo.png"} alt="logo" height={80}/>
            <Info>
                <p>
                  Time Elapsed:
                  00:{Math.floor(props.time / 60).toString().padStart(2, '0')}:{(props.time % 60).toString().padStart(2, '0')}
                </p>
                <p>Section: Class 2B</p>
                <p>Subject: Science</p>
                <Search type="search" placeholder="Search Name/Student No."/>
            </Info>
        </HeaderDiv>
    )
};

const HeaderDiv = styled.div`
    width: 100%;
    height: 80px;
    background-color: #3d3d3d;
    z-index: 999;
    color: white;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
`

const Info = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-evenly;
`

const Search = styled.input`
    height: 30px;
    width: 180px;
    margin-top: 10px;
    background-color: #8a8a8a;
    padding: 16px;
    border: 0;
    border-radius: 4px;
    color: white;
    ::placeholder {
        color: white;
    }
`

const Icon = styled.div``;

export default Header;
