import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {FaMicrophoneSlash, FaVideo, FaLaptop, FaUsers, FaCog} from "react-icons/fa";

const Footer = (props) => {

    return (
        <FooterDiv>
            <Info>
                <IconContainer>
                    <Icon><FaMicrophoneSlash/></Icon>
                    <div style={{ marginTop: -16}}>Mute</div>
                </IconContainer>
                <IconContainer>
                    <Icon><FaVideo/></Icon>
                    <div style={{ marginTop: -16}}>Video</div>
                </IconContainer>
                <IconContainer>
                    <Icon><FaLaptop/></Icon>
                    <div style={{ marginTop: -16}}>Screen Share</div>
                </IconContainer>
                <IconContainer>
                    <Icon><FaUsers/></Icon>
                    <div style={{ marginTop: -16}}>People</div>
                </IconContainer>
                <IconContainer onClick={props.toggleDrawer}>
                    <Icon><FaCog/></Icon>
                    <div style={{ marginTop: -16}}>Preferences</div>
                </IconContainer>
            </Info>
            <ButtonDiv>
                <BeginButton>Begin Test</BeginButton>
                <EndButton>End Test</EndButton>
            </ButtonDiv>
        </FooterDiv>
    )
};

const FooterDiv = styled.div`
    width: 100%;
    height: 80px;
    background-color: #696969;
    z-index: 999;
    color: white;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
`

const Info = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;
`

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    width: 128px;
    padding: 8px 0;
    
    :hover {
        background-color: #525252;
    }
`;

const Icon = styled.div`
    font-size: 32px;
`;

const ButtonDiv = styled.div`
    position: fixed;
    right: 0;
`

const BeginButton = styled.button`
    background-color: #E6A73A;
    color: white;
    border: none;
    padding: 5px 20px;
    width: 125px;
    margin-right: 30px;
`

const EndButton = styled.button`
    background-color: #BA1C16;
    color: white;
    border: none;
    padding: 5px 20px;
    width: 125px;
    margin-right: 30px;
`

export default Footer;
