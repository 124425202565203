import React, {useRef, useState} from 'react';
import styled from 'styled-components'
import MovingImage from "./MovingImage";
import CheatlessWebcam from "./CheatlessWebcam";
import {FaChevronLeft} from "react-icons/fa";
import StudentActivityLog from "./StudentActivityLog";
import AudioAnalyser from "./Solo/AudioAnalyser";

const StudentsView = props => {
    const [studentChosen, setStudentChosen] = useState(false);
    return (
        <Column>
            <Row style={{
              marginTop: 80,
            }} studentChosen={studentChosen}>
                {studentChosen && <Icon onClick={() => setStudentChosen(false)}><FaChevronLeft/></Icon>}
                <MovingImage image={'/images/student1.png'} studentChosen={studentChosen} />
                <MovingImage image={'/images/student2.png'} studentChosen={studentChosen} />
                <MovingImage image={'/images/student3.png'} studentChosen={studentChosen} />
                <MovingImage image={'/images/student4.png'} studentChosen={studentChosen} />
            </Row>
            <Row containsChosenStudent={true} studentChosen={studentChosen}>
                <MovingImage image={'/images/student5.png'} studentChosen={studentChosen} />
                <CheatlessWebcam
                  studentChosen={studentChosen}
                  handleStudentChosen={() => setStudentChosen(true)}
                  dispatch={props.dispatch}
                  startTime={props.startTime}
                />
                <MovingImage image={'/images/student6.png'} studentChosen={studentChosen} />
                <MovingImage image={'/images/student7.png'} studentChosen={studentChosen} />
                    <div style={{
                        transition: 'flex 0.5s ease-in-out',
                        width: studentChosen ? 'auto' : 0,
                        height: studentChosen ? 'auto' : 0,
                        flex: studentChosen ? 1 : 0,
                        opacity: studentChosen ? 1 : 0,
                    }}>
                        <StudentActivityLog state={props.state} />
                        {props.audio && <AudioAnalyser audio={props.audio} dispatch={props.dispatch} time={props.time} />}
                    </div>
            </Row>
            <Row style={{
              marginBottom: 80,
            }} studentChosen={studentChosen}>
                <MovingImage image={'/images/student8.png'} studentChosen={studentChosen} />
                <MovingImage image={'/images/student9.png'} studentChosen={studentChosen} />
                <MovingImage image={'/images/student10.png'} studentChosen={studentChosen} />
                <MovingImage image={'/images/student11.png'} studentChosen={studentChosen} />
            </Row>
        </Column>
    )
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  box-sizing: border-box;
  background-color: black;
  padding: 2px;
  transform: flex 0.5s ease-in-out;
`;

const Row = styled.div`
  display: flex;
  flex: ${props => props.studentChosen ? props.containsChosenStudent ? '1' : '0' : '1'};
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: stretch;
  background-color: black;
  transition: flex 0.5s ease-in-out;
  margin-left: 80px;
  margin-right: 80px;
`;

const Icon = styled.div`
    font-size: 18px;
    padding: 18px;
    cursor: pointer;
    color: white;
`;

export default StudentsView;
