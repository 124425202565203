import React, {useState} from 'react';
import styles from './styles.css'

const MovingImage = (props) => {
  const [randomNumber] = useState(Math.floor(Math.random() * 10) + 5);
  const [randomNumber2] = useState(Math.floor(Math.random() * 10) + 5);
  return (
    <div className="moving-image" style={{
      flex: props.studentChosen ? '0' : '1 0 20%',
      backgroundImage: `url('${props.image}')`,
      animation: `animatedBackground ${randomNumber}s -${randomNumber2}s ease infinite`,
      transition: 'flex 0.5s ease-in-out',
    }}>
    </div>
  )
};

export default MovingImage;
