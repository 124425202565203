import React from 'react';
import { Link } from 'react-router-dom';
import { FaVideo, FaFolderPlus, FaCalendarAlt, FaChartLine, FaQuestion } from 'react-icons/fa';

import styled from 'styled-components'

const Sidebar = (props) => {
    return (
        <SidebarDiv>
            <Nav>
                <Link to="/"><Icon><FaVideo /></Icon></Link>
            </Nav>
            <Nav>
                <Link to="/test"><Icon><FaFolderPlus /></Icon></Link>
            </Nav>
            <Nav>
                <Link to="/a"><Icon><FaCalendarAlt /></Icon></Link>
            </Nav>
            <Nav>
                <Link to="/b"><Icon><FaChartLine /></Icon></Link>
            </Nav>
        </SidebarDiv>
    )
};

const SidebarDiv = styled.div`
  height: 100%;
  width: 80px;
  background-color: #A7803F;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 75px;
`

const Nav = styled.div`
    display: block;
`

const Icon = styled.h1`
    text-align: center;
    margin: 0;
    color: white;
    padding: 20px 0 20px 0;
    &:hover {
        background-color: rgba(235, 149, 50, 1);
    }
`

export default Sidebar;