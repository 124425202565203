import React, {useRef, useState} from 'react';
import styled from "styled-components";
import Cheatless from "./Cheatless";
import Webcam from "react-webcam";
import { useHistory } from "react-router-dom";

const CheatlessWebcam = (props) => {
  const webcamRef = useRef();
  const [userMedia, onUserMedia] = useState(false);

  return (
      <Div chosen={props.studentChosen} onClick={props.handleStudentChosen}>
        <Webcam style={{
          objectFit: 'cover',
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: '100%',
        }} onUserMedia={() => onUserMedia(true)} ref={webcamRef} mirrored={true}/>
        {userMedia && <Cheatless webcam={webcamRef} dipatch={props.dispatch} startTime={props.startTime}/>}
        <canvas style={{display: 'none'}}/>
      </Div>
    )
};

const Div = styled.div`
    flex: ${props => props.chosen ? '2' : '1 0 20%'};
    position: relative;
    display: flex;
    background-size: auto 120%;
    background-repeat: no-repeat;
    margin: 4px;
    cursor: pointer;
    transition: flex 0.5s ease-in-out;
`

export default CheatlessWebcam;
