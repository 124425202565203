import React, { useState, useEffect, useReducer } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components'

import Header from './Header';
import Drawer from './Drawer';
import Sidebar from './Sidebar';
import StudentView from './StudentView';
import StudentsView from './StudentsView';
import history from '../history';
import reducer from '../reducer';

import '../fonts/Poppins-Regular.ttf'
import Footer from "./Footer";

const App = () => {
    const [audio, setAudio] = useState(null);
    const [userMedia, setUserMedia] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [startTime] = useState(new Date());
    const [time, setTime] = useState(0);
    const [state, dispatch] = useReducer(reducer, {logs: []});

    useEffect(() => {
        async function getAudio() {
            const audioPermission = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
              });
              setAudio(audioPermission);
        }
        getAudio();
    }, []);

    const toggleDrawer = () => setDrawerOpen(prev => !prev);

    return (
        <div>
            <Header toggleDrawer={toggleDrawer} startTime={startTime} time={time} setTime={setTime}/>
            <Router history={history}>
                <Sidebar />
                <Switch>
                    <MainArea>
                        <Route path="/" exact>
                            <StudentsView
                                audio={audio} state={state} dispatch={dispatch}
                                startTime={startTime} time={time}/>
                        </Route>
                        <Route path="/student" exact>
                            <StudentView
                                audio={audio}
                            />
                        </Route>
                    </MainArea>
                </Switch>
            </Router>
            <Drawer open={drawerOpen} toggleDrawer={toggleDrawer} />
            <Footer toggleDrawer={toggleDrawer} />
        </div>
    )
};

const MainArea = styled.div`
    height: 100vh;
    overflow: hidden;
`

export default App;
