import React from 'react';
import AudioVisualizer from './AudioVisualizer';
import { valueAndGrads } from '@tensorflow/tfjs';

class AudioAnalyser extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            audioData: new Uint8Array(0),
            amplitude: 128,
            noisyChange: false,
            noisyLength: 0,
            amplitudeChecker: []
        };
        this.tick = this.tick.bind(this);
      }
 
    componentDidMount() {
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        this.analyser = this.audioContext.createAnalyser();
        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.source = this.audioContext.createMediaStreamSource(this.props.audio);
        this.source.connect(this.analyser);
        this.rafId = requestAnimationFrame(this.tick);
        this.createLogs();
    }

    tick() {
        this.analyser.getByteTimeDomainData(this.dataArray);
        const amplitude = Math.max( ...this.dataArray );
        this.setState({ audioData: this.dataArray, amplitude });
        this.rafId = requestAnimationFrame(this.tick);
    }

    createLogs() {
      setInterval(() => {
        if (this.state.amplitude > 140) {
          if (this.state.amplitudeChecker.length < 6) {
            this.setState({...this.state, amplitudeChecker: [...this.state.amplitudeChecker, 'Noisy']})
          } else {
            this.setState({...this.state, amplitudeChecker: [...this.state.amplitudeChecker.slice(1), 'Noisy']})
          }
          
        } else {
          if (this.state.amplitudeChecker.length < 6) {
            this.setState({...this.state, amplitudeChecker: [...this.state.amplitudeChecker, 'x']})
          } else {
            this.setState({...this.state, amplitudeChecker: [...this.state.amplitudeChecker.slice(1), 'x']})
          }
        }
        this.setNoisy();

        if (this.state.noisyChange) {
          if (this.state.noisyLength === 6) {
            this.props.dispatch({type: 'add', payload: {time: this.getTime(), activity: 'Noisy'}});
          } else if (this.state.noisyLength === 1) {
            this.props.dispatch({type: 'add', payload: {time: this.getTime(), activity: 'Not Noisy'}});
          }
        }
      }, 500);
    }

    getLogCount(array, value) {
      return array.filter((v) => (v === value)).length;
    }

    setNoisy() {
      const noisy = (this.getLogCount(this.state.amplitudeChecker, 'Noisy') >= 3 &&
        this.state.amplitudeChecker.length === 6)
      if (noisy) {
        this.setState({
          noisyLength: 6,
          noisyChange: this.state.noisyLength === 0
        });
      } else {
        this.setState({
          noisyLength: Math.max(this.state.noisyLength - 1, 0),
          noisyChange: this.state.noisyLength > 0
        });
      }
      return noisy;
    }

    getTime() {
      const time = this.props.time
      return `00:${Math.floor(time / 60).toString().padStart(2, '0')}:${(time % 60).toString().padStart(2, '0')}`
    }

    render() {
        return <AudioVisualizer audioData={this.state.audioData} />;
      }
}

export default AudioAnalyser;
