const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return {
                ...state,
                logs: [...state.logs, action.payload]
            }
        default:
            return state;
      }
};

export default reducer;