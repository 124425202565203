import React from "react";
import styled from 'styled-components';
import Checkbox from "./Checkbox";

const Checkboxes = (props) => {
    return (
        <div>
            <form style={{display: "flex", flexDirection: "column"}}>
                <Checkbox name="Facing Up" />
                <Checkbox name="Facing Down" defaultChecked={true}/>
                <Checkbox name="Facing Left" />
                <Checkbox name="Facing Right" />
                <Checkbox name="Eye Left" />
                <Checkbox name="Eye Right" />
                <Checkbox name="Covering Face" defaultChecked={true}/>
                <Checkbox name="Using Device" defaultChecked={true}/>
                <Checkbox name="Multiple Persons" defaultChecked={true}/>
                <Checkbox name="Opening other Browsers" defaultChecked={true}/>
                <StartButton type="submit" onClick={props.start}>Save</StartButton>
            </form>
        </div>
    )
};

const StartButton = styled.button`
    width: 100px;
    border-radius: 10px;
    margin: 0 auto;
    background-color: #E6A73A;
    color: white;
    padding: 5px 10px;
    border: none;
`

export default Checkboxes;
